import LiveBlogModel from '../models/live-blog.model';
import CategoryModel from '../../../../models/v2/category/category.model';
import AuthorModel from '../../../../models/v2/author/author.model';
import SportsTypesModel from '../../../../models/v2/sports-types/sports-types.model';
import { slugifyString } from '../../../Partials/Sidebar/seo-refactored/helpers/slug.helper';
import { Button } from 'reactstrap';
import React from 'react';
import MainMediaModel from '../../../../models/v2/main-media/main-media.model';
import LiveBlogCollaboratorsModel from '../models/live-blog-collaborators.model';
import OddClientBookmakersModel from '../../../Partials/Blocky/partials/available-bookmakers-select/models/odd-client-bookmakers-full.model';
import LiveBlogTournamentModel from '../models/tournaments/live-blog-tournament-model';
import LiveBlogTeamModel from '../models/teams/live-blog-team.model';
import LiveBlogMatchModel from '../components/subcomponents/live-blog-events/models/live-blog-match-model';

export enum LiveBlogTypes {
	MATCH = 'SPORT_EVENT',
	LIVE_NEWS = 'STANDARD',
}

export enum LiveBlogStatuses {
	Active = 'ACTIVE',
	ARCHIVED = 'ARCHIVED',
	INACTIVE = 'INACTIVE',
}

export class LiveBlogConfigurationDisabledSaveModel {
	public changedLiveBlogTitle: boolean = false;
	public changedLiveBlogStartTime: boolean = false;
	public changeLiveBlogEndTime: boolean = false;
	public changedLiveBlogCategory: boolean = false;
	public changedLiveBlogMainMedia: boolean = false;
	public changedLiveBlogSport: boolean = false;
	public changedLiveBlogTournament: boolean = false;
	public changedLiveBlogEvents: boolean = false;
	public changedLiveBlogSlug: boolean = false;
	public validLiveBlogTitle: boolean = true;
	public validLiveBlogStartTime: boolean = true;
	public validLiveBlogEndTime: boolean = true;
	public validLiveBlogCategory: boolean = true;
	public validLiveBlogMainMedia: boolean = true;
	public validLiveBlogSport: boolean = true;
	public validLiveBlogTournament: boolean = true;
	public validLiveBlogEvents: boolean = true;
	public validLiveBlogSlug: boolean = true;
}

export const shouldSaveLiveBlogNextButtonBeDisabled = (
	disabledSaveModel: LiveBlogConfigurationDisabledSaveModel,
	screen: number,
	type: string,
	isNewLiveBlog: boolean,
	liveBlog: LiveBlogModel,
) => {
	let isInputContentChanged = false;
	let isInputDataCorrect = false;
	if (disabledSaveModel) {
		switch (screen) {
			case 1:
				if (isNewLiveBlog) {
					isInputContentChanged =
						disabledSaveModel.changedLiveBlogTitle ||
						disabledSaveModel.changedLiveBlogStartTime ||
						disabledSaveModel.changeLiveBlogEndTime ||
						disabledSaveModel.changedLiveBlogCategory ||
						disabledSaveModel.changedLiveBlogSlug ||
						disabledSaveModel.changedLiveBlogMainMedia;
					isInputDataCorrect =
						disabledSaveModel.validLiveBlogTitle &&
						disabledSaveModel.validLiveBlogStartTime &&
						disabledSaveModel.validLiveBlogEndTime &&
						disabledSaveModel.validLiveBlogCategory &&
						disabledSaveModel.validLiveBlogSlug &&
						disabledSaveModel.validLiveBlogMainMedia;
					return !isInputContentChanged || !isInputDataCorrect;
				} else {
					return false;
				}
			case 2:
				if (type === LiveBlogTypes.MATCH) {
					isInputDataCorrect =
						disabledSaveModel.validLiveBlogSport &&
						disabledSaveModel.validLiveBlogTournament &&
						disabledSaveModel.validLiveBlogEvents &&
						liveBlog.sport_events.length > 0;

					return !isInputDataCorrect;
				} else {
					return false;
				}
		}
	}
};

export const isExceedingMaxLength = (value: string, maxLength: number) => {
	return maxLength > value.length;
};

export const convertIdToString = (id: any) => {
	return id.toString();
};

export const liveBlogConfigurationMainFieldsChange = (
	el: HTMLInputElement,
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
	allowAutoGeneratedSlug: boolean,
) => {
	const elId = el.id;
	const elValue = el.value;
	if (elId === 'title') {
		const changedLiveBlogTitle = liveBlog.title !== elValue;
		setDisabledSave({
			...disabledSave,
			changedLiveBlogTitle,
			validLiveBlogTitle: !!elValue,
		});

		setLiveBlog({ ...liveBlog, [elId]: elValue } as LiveBlogModel);
		if (allowAutoGeneratedSlug) {
			const slug = slugifyString(elValue.toString());
			setLiveBlog({ ...liveBlog, [elId]: elValue, slug } as LiveBlogModel);
		}
	} else if (elId === 'description') {
		setLiveBlog({ ...liveBlog, [elId]: elValue } as LiveBlogModel);
	}
};

export const liveBlogConfigurationSlugChange = (
	value: string,
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
) => {
	const slug = slugifyString(value);
	const changedLiveBlogSlug = liveBlog.slug !== value;
	setDisabledSave({
		...disabledSave,
		changedLiveBlogSlug,
		validLiveBlogSlug: !!value,
	});
	setLiveBlog({ ...liveBlog, ['slug']: slug } as LiveBlogModel);
};

export const liveBlogConfigurationCategoryChange = (
	category: CategoryModel,
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
) => {
	const changedLiveBlogCategory = liveBlog.category && category && liveBlog.category.id !== category.id;
	setDisabledSave({
		...disabledSave,
		changedLiveBlogCategory,
		validLiveBlogCategory: !!category,
	});

	setLiveBlog({ ...liveBlog, ['category']: category } as LiveBlogModel);
};

export const liveBlogConfigurationAdditionalCategoriesChange = (
	categories: CategoryModel[],
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
) => {
	const selectedAdditionalCategories: CategoryModel[] = categories && categories.length > 0 ? categories.map((category) => category) : [];
	setLiveBlog({ ...liveBlog, ['additional_categories']: selectedAdditionalCategories } as LiveBlogModel);
};

export const liveBlogConfigurationStartTimeChange = (
	date: Date,
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
) => {
	const changedLiveBlogStartTime = date && liveBlog.start_time !== date.toString();
	setDisabledSave({
		...disabledSave,
		changedLiveBlogStartTime,
		validLiveBlogStartTime: !!date,
	});

	setLiveBlog({ ...liveBlog, ['start_time']: date && date.toString() } as LiveBlogModel);
};

export const liveBlogConfigurationEndTimeChange = (
	date: string | null,
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
) => {
	const changedLiveBlogEndTime = date && liveBlog.start_time !== date.toString();
	setDisabledSave({
		...disabledSave,
		changedLiveBlogEndTime,
		validLiveBlogEndTime: !!date,
	});

	setLiveBlog({ ...liveBlog, end_time: date && date.toString() } as LiveBlogModel);
};

export const liveBlogConfigurationAuthorsChange = (authors: AuthorModel[], liveBlog: LiveBlogModel, setLiveBlog: Function) => {
	const selectedAuthors: AuthorModel[] = authors && authors.length > 0 ? authors.map((author) => author) : [];
	setLiveBlog({ ...liveBlog, ['authors']: selectedAuthors } as LiveBlogModel);
};

export const liveBlogConfigurationCollaboratorsChange = (
	collaborators: LiveBlogCollaboratorsModel[],
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
) => {
	const selectedCollaborators: LiveBlogCollaboratorsModel[] =
		collaborators && collaborators.length > 0 ? collaborators.map((collaborator) => collaborator) : [];
	setLiveBlog({ ...liveBlog, ['collaborators']: selectedCollaborators } as LiveBlogModel);
};

export const liveBlogConfigurationEventsChange = (
	events: LiveBlogMatchModel[],
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
) => {
	const changedLiveBlogEvents = liveBlog.sport_events !== events;
	setDisabledSave({
		...disabledSave,
		changedLiveBlogEvents,
		validLiveBlogEvents: !!events,
	});

	const selectedEvents: any[] = events && events.length > 0 ? events.map((event) => event) : [];
	setLiveBlog({ ...liveBlog, ['sport_events']: selectedEvents } as LiveBlogModel);
};

export const liveBlogConfigurationMainMediaChange = (
	media: MainMediaModel[],
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
) => {
	const changedLiveBlogMainMedia = liveBlog.main_media && media && liveBlog.main_media !== media;
	setDisabledSave({
		...disabledSave,
		changedLiveBlogMainMedia,
		validLiveBlogMainMedia: !!media,
	});

	const selectedMedias: MainMediaModel[] = media && media.length > 0 ? media.map((item) => item) : [];

	setLiveBlog({ ...liveBlog, ['main_media']: selectedMedias } as LiveBlogModel);
};

export const liveBlogConfigurationStatusChange = (status: string, liveBlog: LiveBlogModel, setLiveBlog: Function) => {
	setLiveBlog({ ...liveBlog, ['status']: status } as LiveBlogModel);
};

export const liveBlogConfigurationTypeChange = (type: string, liveBlog: LiveBlogModel, setLiveBlog: Function) => {
	setLiveBlog({ ...liveBlog, ['type']: type } as LiveBlogModel);
};

export const liveBlogConfigurationMainMediaTypeChange = (type: string, liveBlog: LiveBlogModel, setLiveBlog: Function) => {
	setLiveBlog({ ...liveBlog, ['main_media_type']: type } as LiveBlogModel);
};

export const liveBlogConfigurationSportChange = (
	sport: SportsTypesModel,
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
) => {
	const changedLiveBlogSport = sport && liveBlog.sport !== sport.title;
	setDisabledSave({
		...disabledSave,
		changedLiveBlogSport,
		validLiveBlogSport: !!sport,
	});

	setLiveBlog({ ...liveBlog, ['sport']: sport && sport.sport.toUpperCase() } as LiveBlogModel);
};

export const liveBlogConfigurationTournamentChange = (
	tournament: LiveBlogTournamentModel | null,
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	setDisabledSave: Function,
) => {
	const changedLiveBlogSport = liveBlog.competitions && liveBlog.competitions[0] !== tournament;
	setDisabledSave({
		...disabledSave,
		changedLiveBlogSport,
		validLiveBlogTournament: !!tournament,
	});

	setLiveBlog({ ...liveBlog, ['competitions']: tournament ? [tournament] : null } as LiveBlogModel);
};

export const liveBlogConfigurationTeamsChange = (teams: LiveBlogTeamModel[], liveBlog: LiveBlogModel, setLiveBlog: Function) => {
	const selectedTeams: LiveBlogTeamModel[] = teams && teams.length > 0 ? teams.map((team) => team) : [];
	setLiveBlog({ ...liveBlog, ['teams']: selectedTeams } as LiveBlogModel);
};

export const liveBlogConfigurationLanguageChange = (language: string, liveBlog: LiveBlogModel, setLiveBlog: Function) => {
	setLiveBlog({ ...liveBlog, ['language']: language } as LiveBlogModel);
};

export const liveBlogConfigurationPaginationChange = (pagination: number, liveBlog: LiveBlogModel, setLiveBlog: Function) => {
	setLiveBlog({ ...liveBlog, ['pagination']: Number(pagination) } as LiveBlogModel);
};

export const liveBlogConfigurationMatchHeaderChange = (toggle: boolean, liveBlog: LiveBlogModel, setLiveBlog: Function) => {
	setLiveBlog({ ...liveBlog, ['match_header']: toggle } as LiveBlogModel);
};

export const liveBlogConfigurationSponsorsChange = (
	sponsors: OddClientBookmakersModel[],
	liveBlog: LiveBlogModel,
	setLiveBlog: Function,
) => {
	const selectedSponsors: OddClientBookmakersModel[] = sponsors && sponsors.length > 0 ? sponsors.map((sponsor: any) => sponsor) : [];
	setLiveBlog({ ...liveBlog, ['sponsors']: selectedSponsors } as LiveBlogModel);
};

export const determineStepperTab = (
	isNewLiveBlog: boolean,
	type: string,
	step: number,
	pagePane: any,
	firstStepValidData: boolean,
	secondStepValidData: boolean,
	changePage: Function,
) => {
	if (!isNewLiveBlog || (type === LiveBlogTypes.LIVE_NEWS && firstStepValidData)) {
		return changePage(pagePane, step);
	} else if (!isNewLiveBlog || type === LiveBlogTypes.MATCH) {
		if (firstStepValidData) {
			return changePage(pagePane, step);
		} else if (firstStepValidData && secondStepValidData) {
			return changePage(pagePane, step);
		}
	}
};

export const determineConfigurationStage = (
	t: any,
	type: string | null,
	selectedPage: any,
	changePage: Function,
	handleLiveBlogSave: Function,
	shouldSaveLiveBlogNextButtonBeDisabled: Function,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	isNewLiveBlog: boolean,
	liveBlog: LiveBlogModel,
	isRequestInProgress: boolean,
) => {
	if (selectedPage[0] === 2 && type === LiveBlogTypes.LIVE_NEWS) {
		return (
			<>
				<Button
					className='ml-1'
					type='button'
					color='success'
					bssize='sm'
					onClick={(e) => handleLiveBlogSave(e)}
					disabled={
						shouldSaveLiveBlogNextButtonBeDisabled(disabledSave, selectedPage[0], type ? type : '', isNewLiveBlog, liveBlog) ||
						isRequestInProgress
					}
				>
					{isNewLiveBlog ? t('create') : t('save')}
				</Button>
			</>
		);
	} else if (selectedPage[0] === 3 && type === LiveBlogTypes.MATCH) {
		return (
			<>
				<Button
					className='ml-1'
					type='button'
					color='success'
					bssize='sm'
					onClick={(e) => handleLiveBlogSave(e)}
					disabled={
						shouldSaveLiveBlogNextButtonBeDisabled(disabledSave, selectedPage[0], type ? type : '', isNewLiveBlog, liveBlog) ||
						isRequestInProgress
					}
				>
					{isNewLiveBlog ? t('create') : t('save')}
				</Button>
			</>
		);
	} else {
		return (
			<>
				<Button
					className='ml-1'
					type='button'
					color='success'
					bssize='sm'
					onClick={() => changePage(0, selectedPage[0] + 1)}
					disabled={shouldSaveLiveBlogNextButtonBeDisabled(disabledSave, selectedPage[0], type ? type : '', isNewLiveBlog, liveBlog)}
				>
					{t('next')}
				</Button>
			</>
		);
	}
};

export const handleStepperChange = (
	type: string | null,
	isNewLiveBlog: boolean,
	page: number,
	disabledSave: LiveBlogConfigurationDisabledSaveModel,
	changePage: Function,
) => {
	const firstStepValidData =
		disabledSave.validLiveBlogMainMedia &&
		disabledSave.validLiveBlogCategory &&
		disabledSave.validLiveBlogTitle &&
		disabledSave.validLiveBlogStartTime &&
		disabledSave.validLiveBlogSlug;

	const secondStepValidData = disabledSave.validLiveBlogSport && disabledSave.validLiveBlogEvents && disabledSave.validLiveBlogTournament;

	if (type === LiveBlogTypes.LIVE_NEWS && firstStepValidData) {
		if (page === 2) {
			return changePage(0, page);
		} else {
			return page === 1 && changePage(0, page);
		}
	} else if (!isNewLiveBlog || type === LiveBlogTypes.MATCH) {
		if (firstStepValidData && page === 2) {
			return changePage(0, page);
		} else if (firstStepValidData && secondStepValidData && page === 3) {
			return changePage(0, page);
		} else {
			return page === 1 && changePage(0, page);
		}
	}
};
